import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Date from '@components/api-resource/resource-entry-fields/date'
import { type ApiPlatformEntity } from '@interfaces/api'
import {
  type BookingRequestListEntryProps
} from '@pages/booking-requests/list/booking-requests-list-entry/booking-requests-list-entry.interfaces'
import { isPropertyType } from '@services/tools/api-resources/property-types'
import { BookingStatuses } from '@services/tools/booking-helpers'
import clsxm from '@services/tools/clsxm'

const BookingRequestsListEntry = <T extends ApiPlatformEntity>({ entry, fieldsToDisplay, onClick }: BookingRequestListEntryProps<T>) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })

  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if (field.properties && 'type' in field.properties) {
        if (isPropertyType(field, 'string')) {
          if (field.name.toLowerCase().includes('state')) {
            fields.push(
              <span
                className={clsxm(
                  BookingStatuses[entry[field.name]],
                  'rounded-md py-1 px-2 text-primaryDark text-xs font-medium whitespace-nowrap'
                )}
              >
                {translateResource(`states.${field.name}.${entry[field.name]}`)}
              </span>
            )
          } else if (field.properties.format === 'date-time' && entry[field.name] && (field.name === 'checkInDate' || field.name === 'checkOutDate')) {
            fields.push(<Date value={entry[field.name]} />)
          } else if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date datetimeEnabled value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        } else {
          fields.push(<div key={index}>{entry[field.name]}</div>)
        }
      } else {
        if (field.name === 'user') {
          fields.push(<div key={index}>{entry[field.name] ? entry[field.name].email : ''}</div>)
        } else if (field.name === 'hotel') {
          fields.push(<div key={index}>{entry[field.name] ? entry[field.name].name.fr : ''}</div>)
        } else {
          fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
        }
      }
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='p-3 text-base'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default BookingRequestsListEntry
