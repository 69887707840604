import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import type { BookingRequest } from '@interfaces/api/booking-request/booking-request'
import { BookingRequestLayout } from '@pages/booking-requests/components/booking-request-layout'
import OfferForm from '@pages/booking-requests/offer-form/offer-form'
import useCreateEntrySimple from '@services/api/resources/create-entry-query-simple'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'

const OfferCreate = () => {
  const { id = '' } = useParams()
  const navigate = useNavigate()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })

  const {
    data: bookingRequest
  } = useApiResourceItemQuery<BookingRequest>({ id, path: 'booking-requests/{uid}', refetchOnMount: 'always' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') },
    { href: `/booking-requests/${bookingRequest?.uid}`, name: `Demande ${bookingRequest?.user?.email ?? ''}` }
  ]

  const { mutateAsync: postOffer } = useCreateEntrySimple({ path: 'offers' })

  const onOfferSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true)

    try {
      if (bookingRequest) {
        await postOffer({
          data: {
            bookingRequest: bookingRequest['@id'],
            externalPrices: {
              booking: values.externalPrices.booking * 100,
              expedia: values.externalPrices.expedia * 100
            },
            label: values.label,
            rooms: values.rooms.map(room => ({
              rates: room.rates.map(rate => ({
                minimumMargin: rate.minimumMargin * 100,
                rateKey: rate.rateKey,
                sellingPrice: rate.sellingPrice * 100
              })),
              roomCode: room.room.code
            }))
          }
        })

        // TODO REFETCH OFFERS
        resetForm()
        navigate(`/booking-requests/${id}`)
      }
    } catch (error) {
      const err = error as Error
      captureException(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${bookingRequest?.user.email ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {bookingRequest
          ? (
            <BookingRequestLayout bookingRequest={bookingRequest}>
              <>
                <OfferForm
                  bookingRequest={bookingRequest}
                  onSubmit={onOfferSubmit}
                />
              </>
            </BookingRequestLayout>
          )
          : <></>}
      </Guard>
    </Layout>
  )
}

export default OfferCreate
