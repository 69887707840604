import React from 'react'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import {
  type UsersBookingRequestsInterfaces
} from '@pages/users/components/users-booking-requests/users-booking-requests.interfaces'

const UserBookingRequests = ({ breadcrumbs, id }: UsersBookingRequestsInterfaces) => {
  const navigate = useNavigate()

  const onEntryClick = (entry) => {
    navigate(`/booking-requests/${entry.uid}`, { state: { breadcrumbs } })
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mb-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Booking Requests</h3>
            </div>
          </div>
        </div>

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['hotel', 'state', 'checkInDate', 'checkOutDate', 'roomCount', 'adultCount', 'childCount', 'expiresAt', 'createdAt']}
          customFilters={['state']}
          customParams={[{ user: id }]}
          hasMargin={false}
          name={'booking-requests'}
          onClick={onEntryClick}
        />
      </div>
    </>
  )
}

export default UserBookingRequests
